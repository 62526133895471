import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import {RiArrowRightLine, RiArrowLeftLine} from 'react-icons/ri'
import TOC from '$components/TOC'
import smoothScroll from '$components/TOC/smoothScroll'
import './step-page.css'

class BlogPostTemplate extends React.Component {
  componentDidMount() {
    const { hash } = this.props.location;
    if (hash && hash.length > 0) {
      setTimeout(() => {
        smoothScroll.scrollTo(hash.replace('#',''))
      }, 100);
    }
  }
  
  // A blog post layout should never be re-rendered...
  // I think...
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }
  
  render() {
    const post = this.props.data.mdx;
    const { frontmatter, tableOfContents } = post;
    const { previous, next } = this.props.pageContext;
    
    return (
      <>
        <TOC links={tableOfContents.items} />
          
        <div className="page-content">
        <h1>{frontmatter.title}</h1>
        
        <MDXRenderer>{post.body}</MDXRenderer>

        <nav className="page-nav">
          {previous ?
            <Link className="page-nav-link" to={`/${previous.slug}`} rel="prev">
              <RiArrowLeftLine /> {previous.frontmatter.title}
            </Link>
          : <span />}
          {next ?
            <Link className="page-nav-link" to={`/${next.slug}`} rel="next">
              {next.frontmatter.title} <RiArrowRightLine />
            </Link>
          : null}
        </nav>
        </div>
      </>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(slug: { eq: $slug }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
      }
      tableOfContents
      body
    }
  }
`
