import React from 'react'
import smoothScroll from './smoothScroll'
import './TOC.css'

class Scrollsy extends React.Component {
  state = {
    index: 0
  }
  
  componentDidMount() {
    if (this.props.items && this.props.items.length) {
      this.offsets = this.props.items.map((link) => {
        const anchor = document.getElementById(link.url.replace('#',''));
        if (anchor) {
          return anchor.offsetTop;
        }
      });
      
      window.addEventListener('scroll', this.scroll);
    }
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scroll);
  }
  
  scroll = (e) => {
    const bodyScroll = e.target.documentElement.scrollTop;
    let index = 0;
    
    while (index <= this.offsets.length - 2) {
      if (bodyScroll < this.offsets[index+1] - 100) {
        break;
      }
      index++;
    }
    
    // Don't cause unnecessary re-renders
    if (this.state.index !== index) {
      this.setState({ index });
    }
  }
  
  render() {
    if (this.props.items && this.props.items.length) {
      return (
        <nav>
          {this.props.items.map((item, i) => {
            let className = 'toc-link '
            className += i === this.state.index ? 'is-current' : '';
            return (
              <a key={item.title}
                className={className}
                href={item.url}
                onClick={(e) => {
                  smoothScroll.scrollTo(item.url);
                  e.preventDefault();
                  document.location.hash = item.url;
                }}>
                  {item.title}
              </a>
            )
          })}
        </nav>
      )
    } else {
      return null
    }
  }
}

export default ({ links }) => (
  <div className="toc">
    <div className="toc-inner">
      <h4>Table of contents</h4>
      <Scrollsy items={links} />
    </div>
  </div>
)
